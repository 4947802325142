.header {
	width: 100%;
	height: 100%;
}
.lection {
	background: #f4f4f4 !important;
}
.header_container {
	position: relative;
}
.man {
	position: absolute;
	top: 15%;
	left: 60%;
	height: 60%;
}
.logo {
	position: absolute;
	width: 30%;
	top: 17%;
	left: 20%;
}
.header_img {
	width: 100%;
	height: auto;
}

.header h1 {
	margin: 0;
}

.navBar {
	background: linear-gradient(
		0deg,
		rgba(252, 246, 234, 0) 0%,
		rgba(252, 246, 234, 0.9) 50%
	);
	box-sizing: border-box;
	top: 0;
	position: absolute;
	margin: 0 auto;
	height: clamp(5rem, -2.1429rem + 11.1607vw, 11.25rem);
	width: 100%;
	z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	min-height: 180px;
}
.bcg_signUp {
	position: relative;
	min-height: 0;
	width: 100%;
}
.signUp {
	min-height: 0;
	width: 100%;
}
.navBar_container {
	width: 80%;
	height: 100%;
	max-width: 1080px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 25px 0 0 0;
	z-index: 1;
}
.link_logo {
	cursor: pointer;
}
.link {
	text-decoration: none;
	position: relative;
	height: min-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}
.link_btn {
	text-decoration: none;
	position: relative;
	height: min-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}
.link_map {
	box-sizing: border-box;
	width: 45px;
	height: 45px;
	flex-shrink: 0;
	background: url('./maps.svg');
	background-size: contain;
	z-index: 2;
	position: relative;
	bottom: 10px;
}
.link:hover {
	.btnBorder_up_smalle {
		display: block;
	}
	.btnBorder_up_overSmalle {
		display: block;
	}
	.btnBorder_up_mediume {
		display: block;
	}
	.btnBorder_up_large {
		display: block;
	}
	.btnBorder_und_smalle {
		display: block;
	}
	.btnBorder_und_mediume {
		display: block;
	}
	.btnBorder_und_large {
		display: block;
	}
	.btnBorder_und_overSmalle {
		display: block;
	}
}
.link_btn:hover {
	.btnBorder_up_smalle {
		display: block;
	}
	.btnBorder_up_overSmalle {
		display: block;
	}
	.btnBorder_up_mediume {
		display: block;
	}
	.btnBorder_up_large {
		display: block;
	}
	.btnBorder_und_smalle {
		display: block;
	}
	.btnBorder_und_mediume {
		display: block;
	}
	.btnBorder_und_large {
		display: block;
	}
	.btnBorder_und_overSmalle {
		display: block;
	}
}
.link_text {
	font-family: 'Montserrat';
	font-size: 20px; /*clamp(1rem, 0.6429rem + 0.558vw, 1.3125rem);*/
	font-style: normal;
	font-weight: 600;
	line-height: 106.9%; /* 21.38px */
	width: max-content;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	cursor: pointer;
}
.btnBorder_up_overSmalle {
	display: none;
	position: absolute;
	right: -55%;
	top: -100%;
}
.btnBorder_up_smalle {
	display: none;
	position: absolute;
	right: -35%;
	top: -100%;
}
.btnBorder_up_mediume {
	display: none;
	position: absolute;
	right: -20%;
	top: -100%;
}
.btnBorder_up_large {
	display: none;
	position: absolute;
	right: -15%;
	top: -100%;
}
.btnBorder_und_overSmalle {
	display: none;
	position: absolute;
	bottom: -100%;
	max-width: 150px;
}
.btnBorder_und_smalle {
	display: none;
	position: absolute;
	bottom: -100%;
	max-width: 150px;
}
.btnBorder_und_mediume {
	display: none;
	position: absolute;
	bottom: -100%;
	width: 175px;
}
.btnBorder_und_large {
	display: none;
	position: absolute;
	bottom: -100%;
	width: 222px;
}

.green_light{
	color: #4ec802;
}
.green {
	color: #778e2d;
	
}
.blue {
	color: #6092d7;
}
.yellow {
	color: #ecb80e;
}
.orange {
	color: #d48028;
}
.red {
	color: #c44819;
}
.purple {
	color: #66459e;
}
.icon_styles_orange {
	display: none;
}
.man_hover {
	display: none;
}
.bcg_lk {
	position: relative;
	min-height: 80px !important;
}
@media (max-width: 960px) {
	.btn_icon {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 200;
	}
	.link_map {
		position: absolute;
		left: 10px;
		top: 10px;
	}

	.header_close {
		display: none;
	}
	.btn_fixed {
		position: fixed;
	}
	.navBar_container {
		z-index: 10;
		margin-top: 25px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		height: 100%;
		width: 90%;
	}
	.mobile_container {
		height: 100vh;
	}
	.header_open {
		min-height: 100vh;
		height: 100%;
		position: fixed;
		top: 0;
		z-index: 109;
		background: #f3f2e9;
		.icon_styles_orange {
			position: fixed;
		}
	}
	.logo {
		width: 50%;
		left: 10%;
		top: 10%;
	}
	.link_btn,
	.link {
		box-sizing: border-box;
		margin-bottom: 35px;
	}

	.man_menu_second {
		width: auto;
		position: fixed;
		display: block;
		height: 60%;
		width: auto;
		left: 0%;
		bottom: 0;
		z-index: 110;
	}
	.man_menu {
		width: auto;
		position: fixed;
		display: block;
		left: 0%;
		top: 385px;
		z-index: 110;
		transform: rotateY(180deg);
	}
	.icon_styles_orange {
		display: block;
		fill: #ee8b3f;
		z-index: 20;
	}
}
@media (max-width: 600px) {
	.man_menu_second {
		display: none;
	}
}
@media (max-width: 380px) {
	.logo {
		left: 2%;
		width: 59%;
	}
}