.selectBox {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0.5% 0.5%;
	box-sizing: border-box;
	position: relative;
}
.dropdown {
	width: 95%;
	background: #f9fcf3;
	z-index: 1000;
	border-radius: 10px;
}
.dropdownList {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	margin: 15px;
	width: 90%;
	box-sizing: border-box;
	height: 120px;
}
.selectBox_btn {
	width: 95%;
	height: 200px;
	border: 0;
	background: local;
	text-align: start;
	padding: 7px;
	color: #303030;
	font-family: 'Montserrat';
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 106.9%; /* 14.966px */
}
.selectBox_btn:hover {
	border-radius: 16px;
	background: #f8edd0;
}
.dropdown_padding {
	box-sizing: border-box;
	position: absolute;
	top: calc(100% + 3px); /* Появляется под кнопкой */
	right: 0;
	z-index: 1; /* Чтобы список был над остальными элементами */
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	padding-left: 0px;
	align-items: center;
	flex-shrink: 0;
	border-radius: 10px;
	background: #f9fcf3;
	z-index: 3;
}

.dropdownList::-webkit-scrollbar {
	width: 12px; /* Ширина ползунка */
	margin-left: 20px;
}

.dropdownList::-webkit-scrollbar-thumb {
	background-color: #f0c042; /* Цвет ползунка */
	border-radius: 6px; /* Закругленные углы ползунка */
}

.value {
	min-height: 13px;
	margin: 0;
	padding: 0;
	border: 0;
	height: 100%;
	width: 100%;
	color: #303030;
	text-align: center;
	font-family: 'Montserrat';
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 106.9%; /* 14.966px */
	background: local;
	border-bottom: 1px solid #303030;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}
.value:hover {
	cursor: pointer;
}
.field {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.field_name {
	font-family: 'Montserrat';
	font-size: clamp(0.25rem, 0.0648rem + 0.9877vw, 1.25rem);
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: center;
	margin: 0;
	text-transform: none;
	word-wrap: break-word;
	width: max-content;
	white-space: nowrap;
	box-sizing: border-box;
	margin-right: 2%;
}

.arrow{
	cursor: pointer;
	width: 20px;
	height: auto;
}
.arrow_open{
	transform: rotate(180deg);
}
@media (max-width: 960px) {
	.field_name {
		font-size: 13px;
	}
	.field_value {
		font-size: 14px;
	}
}
