.rightButton {
	position: absolute;
	bottom: 30px;
	right: 80px;
	cursor: pointer;
	z-index: 200;
}
.btn_back {
	position: absolute;
	cursor: pointer;
	right: 80px;
	bottom: 50px;
	z-index: 100;
	font-family: Hachi Maru Pop;
	font-size: 20px;
	font-weight: 400;
	line-height: 52px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	
}
.btn_back::after {
	content: url('./Arrow_back.png');
	position: absolute;
	top: 50%;
	left: -10%;
	transform: rotate(180deg);
}
.btn_back:hover {
	cursor: pointer;
}
.btn_back::after:hover {
	cursor: pointer;
}
@media (max-width: 960px) {
	.btn_back {
		position: absolute;
	}
}

.rightButton_lection {
	position: absolute;
	bottom: 30px;
	right: 80px;
	cursor: pointer;
	z-index: 1;
}

@media (max-width: 1450px) {
	.rightButton {
		position: absolute;
		bottom: 30px;
		right: 50px;
		cursor: pointer;
		width: 74px;
		height: 74px;
	}
}
@media (max-width: 1130px) {
	.rightButton {
		position: absolute;
		bottom: 30px;
		right: 40px;
		cursor: pointer;
		width: 64px;
		height: 64px;
	}
}

@media (max-width: 960px) {
	.rightButton_lection {
		position: absolute;
		bottom: 30px;
		right: 40px;
		cursor: pointer;
	}
}
@media (max-width: 450px) {
	.rightButton_lection {
		position: absolute;
		bottom: 30px;
		right: 40px;
		cursor: pointer;
		width: 35px;
		height: 35px;
	}
}
