.block-text {
	width: 100%;
	text-align: left;
}
.img-block {
	position: relative;
	border-radius: 40px;
	padding: 40px 40px !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
.double-mark {
	&::after {
		position: absolute;
		top: -40px;
		left: 40px;
		content: url('./img_mark_3.svg');
	}
	&::before {
		position: absolute;
		top: -40px;
		right: 40px;
		position: absolute;
		content: url('./img_mark_4.svg');
	}
}
.block-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 90%;
	flex-wrap: wrap;
}
.block-item {
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-right: 25px;
	width: max-content;
	&:last-of-type {
		margin-right: 0;
	}
	&-img {
		width: 240px;
		height: 240px;
		margin-bottom: 25px;
		border-radius: 50%;
		border: 9px solid #ffffff;
	}
	&-text {
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0em;
		text-align: left;
	}
}
@media (max-width: 770px) {
	.block-item {
		margin-right: 0;
	}
	.double-mark {
		&::after {
			display: none;
		}
		&::before {
			display: none;
		}
	}
}
