@import './variables.scss';
@import './textBox.scss';

.img-box {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.rotate-x-180 {
	transform: rotateY(180deg);
}
.img {
	height: auto;
	width: 100%;

	&-desctiption {
		width: max-content;
		position: absolute;
		background: #e1e1e1;
		box-shadow: -3px 4px 4px 0px #00000024;
		padding: 10px;
		max-width: 240px;
		min-width: 100px;
		box-sizing: border-box;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0em;
		text-align: left;
	}
}

.description-big {
	max-width: 440px;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.animal-t-r {
	position: absolute;
	top: -20px;
	left: 100%;
	background: local;
	box-shadow: none;
	max-width: 200px;
	font-family: 'Comic Sans MS';
}
.animal-t-l {
	position: absolute;
	top: -20px;
	right: 100%;
	background: local;
	box-shadow: none;
	max-width: 200px;
	font-family: 'Comic Sans MS';
}

.animal-t-l-1 {
	position: absolute;
	top: 0px;
	left: 0px;
	font-size: 16px;
	background: local;
	box-shadow: none;
	max-width: 200px;
	font-family: 'Comic Sans MS';
}
.tir-up {
	top: 0;
	right: 0;
	right: -20px;
	transform: rotateZ(6deg);
}
.tir-down {
	bottom: 0;
	right: -20px;

	transform: rotateZ(-6deg);
}
.til-down {
	top: 85%;
	left: -20px;
	transform: rotateZ(6deg);
}
.til-up {
	left: -20px;
	top: 0;
	transform: rotateZ(-6deg);
}
.arrow-tr {
	position: absolute;
	top: 50%;
	left: calc(100% + 40px);
	margin: 50px 0 0 40px;
	background: local;
	padding-left: 5px;
	max-width: 300px;
	width: calc(100% - 55px);
	box-shadow: none;
	&::after {
		content: ' ';
		position: absolute;
		bottom: 25%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		left: -40px;
		z-index: 2;
		box-shadow: none;
		transform: rotateZ(90deg);
	}
}

.arrow-tl {
	position: absolute;
	top: 50%;
	right: calc(100% + 10px);
	margin: 50px 0 0 40px;
	background: local;
	padding-left: 5px;
	max-width: 300px;
	width: calc(100% - 55px);
	box-shadow: none;
	&::after {
		content: ' ';
		position: absolute;
		bottom: 25%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: 30px;
		z-index: 2;
		box-shadow: none;
		transform: rotateZ(-90deg) rotateY(180deg);
	}
}
.arrow-bl {
	position: absolute;
	top: 100%;
	margin: 50px 0 0 40px;
	background: local;
	padding-left: 5px;
	max-width: 300px;
	width: calc(100% - 55px);
	box-shadow: none;
	&::after {
		content: ' ';
		position: absolute;
		bottom: 25%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		left: -40px;
		z-index: 2;
		box-shadow: none;
	}
}
.arrow-br {
	position: relative;
	margin: 60px 40px 0 0;
	background: local;
	padding-right: 5px;
	max-width: 300px;
	width: calc(100% - 55px);
	box-shadow: none;

	&::after {
		content: ' ';
		position: absolute;
		bottom: 50%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateY(180deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: -40px;
		z-index: 2;
		box-shadow: none;
	}
}
.arrow-rb {
	position: absolute;
	top: 20%;
	left: calc(100% + 100px);
	margin: 10px 40px 0 0;
	background: local;
	padding-right: 5px;
	max-width: 300px;
	width: calc(100% - 55px);
	box-shadow: none;

	&::after {
		content: ' ';
		position: absolute;
		bottom: 50%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateY(0deg) rotateZ(-120deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		top: 80%;
		right: 100%;
		z-index: 2;
		box-shadow: none;
	}
}

.mark {
	&-h {
		&::after {
			content: url('./img_mark_2.svg');
			position: absolute;
			right: 20px;
			top: -25px;
			transform: rotateX(180deg);
		}
	}
	&-h-t-r {
		&::after {
			content: url('./img_mark_2.svg');
			position: absolute;
			right: 5px;
			top: -25px;
			transform: rotateX(180deg);
		}
	}
	&-h-b-r {
		&::after {
			content: url('./img_mark_2.svg');
			position: absolute;
			right: 5px;
			bottom: -25px;
			transform: rotateX(180deg);
		}
	}
	&-h-t-l {
		&::after {
			content: url('./img_mark_2.svg');
			position: absolute;
			left: 5px;
			top: -25px;
			transform: rotateX(180deg);
		}
	}
	&-h-b-l {
		&::after {
			content: url('./img_mark_2.svg');
			position: absolute;
			left: 5px;
			bottom: -25px;
			transform: rotateX(180deg);
		}
	}
	&-d-r-t {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			right: -20px;
			top: -25px;
			transform: rotateX(180deg);
		}
	}
	&-d-r-b {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			right: -30px;
			bottom: -30px;
		}
	}
	&-d-l-b {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			left: -20px;
			bottom: -25px;
			transform: rotateX(180deg);
		}
	}
	&-d-l-t {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			left: -20px;
			top: -25px;
		}
	}
	&-double {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			right: -20px;
			top: -25px;
			transform: rotateX(180deg);
		}
		&::before {
			content: url('./img_mark_1.svg');
			position: absolute;
			left: -25px;
			top: -25px;
			z-index: 2;
		}
	}
	&-double-d {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			right: -20px;
			top: -25px;
			z-index: 2;
			transform: rotateX(180deg);
		}
		&::before {
			content: url('./img_mark_1.svg');
			position: absolute;
			left: -20px;
			bottom: -25px;
			z-index: 2;
			transform: rotateX(180deg);
		}
	}
	&-double-d-2 {
		&::after {
			content: url('./img_mark_1.svg');
			position: absolute;
			left: -20px;
			top: -25px;
			z-index: 2;
			transform: rotateX(0deg);
		}
		&::before {
			content: url('./img_mark_1.svg');
			position: absolute;
			right: -20px;
			bottom: -25px;
			z-index: 2;
			transform: rotateX(00deg);
		}
	}
}
.arrow-tl-1 {
	&::after {
		content: ' ';
		position: absolute;
		top: 10px;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateZ(230deg) rotateY(180deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: calc(100% + 25px);
		z-index: 2;
		box-shadow: none;
	}
}
@media (max-width: 800px) {
	.arrow-rb {
		&::after {
			background: local;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: left;
		}
		margin: 0;
		width: max-content;
		background: #e1e1e1;
		box-shadow: -3px 4px 4px 0px #00000024;
		padding: 10px;
		min-width: 100px;
		box-sizing: border-box;
		position: relative;
		left: auto;
		bottom: auto;
		right: 0;
		transform: rotateZ(0);
		width: 100%;
		max-width: none;
		height: min-content;
	}
}
@media (max-width: 995px) {
	.arrow-tl,
	.arrow-tr {
		&::after {
			background: local;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: left;
		}
		margin: 0;
		width: max-content;
		background: #e1e1e1;
		box-shadow: -3px 4px 4px 0px #00000024;
		padding: 10px;
		min-width: 100px;
		box-sizing: border-box;
		position: relative;
		left: auto;
		bottom: auto;
		right: 0;
		transform: rotateZ(0);
		width: 100%;
		max-width: none;
		height: min-content;
	}
}

@media (max-width: 580px) {
	[class*='mark'] {
		&::after {
			content: '';
		}
		&::before {
			content: '';
		}
	}

	.arrow-rb,
	.tir-up,
	.tir-down,
	.til-down,
	.til-up {
		&::after {
			background: local;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: left;
		}
		margin: 0;
		width: max-content;
		background: #e1e1e1;
		box-shadow: -3px 4px 4px 0px #00000024;
		padding: 10px;
		min-width: 100px;
		box-sizing: border-box;
		position: relative;
		left: auto;
		bottom: auto;
		right: 0;
		transform: rotateZ(0);
		width: 100%;
		max-width: none;
		height: min-content;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0em;
		text-align: left;
	}
	.mark-r,
	.mark-l,
	.mark-double {
		&::after,
		&::before {
			display: none;
		}
	}
}
@media (max-width: 1080px) {
	.arrow-tl-1 {
		&::after {
			display: none;
		}
	}
}
@media (max-width: 1366px) {
	.description-big {
		&::after {
			background: local;
			font-family: Montserrat;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: left;
		}
		margin: 0;
		width: max-content;
		background: #e1e1e1;
		box-shadow: -3px 4px 4px 0px #00000024;
		padding: 10px;
		min-width: 100px;
		box-sizing: border-box;
		position: relative;
		left: auto;
		bottom: auto;
		right: 0;
		transform: rotateZ(0);
		width: 100%;
		max-width: none;
		height: min-content;
	}
}
