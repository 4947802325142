@import url('../../variables.css');
body {
	font-family: var(--primary-font);
}

.newsPage {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: #8a954d;
}
.greatContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: #8a954d;
}
.header_img {
	max-width: 100vw;
	width: 100%;
	height: auto;
}
.newsContainer {
	height: 100%;
	width: 90%;
	max-width: 1080px;
	margin: auto;
	margin-top: -40%;
	margin-bottom: 30px;
}
.newsContainer_full {
	width: 98%;
	margin: auto;
	margin-top: -40%;
	height: 100%;
	margin-bottom: 10%;
}
.newsContainer::-webkit-scrollbar {
	width: 6px;
	height: 90px;
}

/* Track */
.newsContainer::-webkit-scrollbar-track {
	background: gainsboro;
	border-radius: 5px;
}

/* Handle */
.newsContainer::-webkit-scrollbar-thumb {
	background: #f7faf1;
	border-radius: 5px;
}

/* Handle on hover */
.newsContainer::-webkit-scrollbar-thumb:hover {
	background: #555;
}
nav {
	margin-bottom: 20px;
}
