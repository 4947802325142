.errorBox_bcg {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 2;
	background: rgba(0, 0, 0, 0.65);
}
.errorBox {
	margin: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	width: 550px;
	background: no-repeat url(./textBox.svg) center center;
	height: 20%;
}
.errorBox_message {
	position: relative;
	display: flex;

	justify-content: center;
	align-items: center;
	width: 190%;
    height: 70%;
	min-width: 320px;
	color: #698B21;
	flex-shrink: 0;
	text-align: center;
	font-family: 'Pangolin';
	font-size: clamp(1.25rem, 1.0225rem + 0.9709vw, 2.1875rem);
	font-style: normal;
	font-weight: 400;
	line-height: 154.4%; /* 61.76px */
	margin: 0;
	flex-wrap: wrap;
	max-width: 550px;
}
.errorBox_exit {
	position: absolute;
	z-index: 1;
	background: local;
	border: 0;
	right: 2%;
	top: 5px;
	cursor: pointer;
	width: 5%;
	svg {
		width: 100%;
	}
}

.question {
	display: flex;
	width: 20%;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 2px;
	flex-shrink: 0;
	border-radius: 33px;
	background: #698b21;
	color: #fff;
	text-align: center;
	font-family: Pangolin;
	font-size: 23px;
	font-style: normal;
	font-weight: 400;
	line-height: 106.9%; /* 24.587px */
	letter-spacing: 1.61px;
	border: 0;
	position: absolute;
	top: calc(50% + 10px);
	cursor: pointer;
}

@media (max-width: 1300px) {
	.errorBox_message {
		width: 150%;
	}
	.errorBox_exit {
		right: 3%;
	}
}

@media (max-width: 540px) {
	.errorBox_message {
		width: 90%;
		min-width: 270px;
		padding: 5%;
		height: 100%;
	}
	.errorBox_exit {
		right: 7%;
		top: 5%;
	}
	.errorBox {
		width: 300px;
		height: 15%;
	}
}

@media (max-width: 377px) {
	
	.errorBox {
		width: 300px;
		height: 24%;
	}
}