.container {
	background: #f4f4f4;
	overflow-x: hidden;
	min-height: 100vh;
	position: relative;
}
.content_info {
	position: relative;
}
div, p { 
	-webkit-text-size-adjust: auto;
  }
.content {
	padding-top: 75px;
	padding-bottom: 60px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
iframe {
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;
}
.content_name {
	font-family: Hachi Maru Pop;
	font-size: clamp(1.125rem, 0.9rem + 1.125vw, 2.25rem);
	font-weight: 400;
	line-height: 52px;
	letter-spacing: 0em;
	text-align: center;
	width: 60vw;
	margin: 0 auto;
	overflow-wrap: normal;
	word-wrap: normal;
	word-break: normal;
	line-break: auto;
	hyphens: manual;
	position: relative;
	z-index: 2;
}
.content_element {
	width: 100%;
	height: 100%;
}
.btn_back {
	position: absolute;
	left: 20px;
	top: 100px;
	z-index: 100;
	font-family: Hachi Maru Pop;
	font-size: 20px;
	font-weight: 400;
	line-height: 52px;
	letter-spacing: 0em;
	text-align: center;
	color: #000000;
	&::after {
		content: url('./Arrow_back.png');
		position: absolute;
		top: 50%;
		left: -0%;
	}
}
@media (max-width: 960px) {
	.btn_back {
		position: absolute;
		left: 20px;
		top: 0px;
	}
}
