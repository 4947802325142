@import './variables.scss';

.textBox {
	border-radius: 40px;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px 40px 40px 40px;
	box-sizing: border-box;
	background: local;
}
@media (max-width: 750px) {
	.textBox {
		padding: 40px 20px;
	}
}
.textBox-vk {
	&::after {
		position: absolute;
		width: 260px;
		height: 200px;
		content: url('./img_descrip_arrow.svg');
		z-index: 20;
		bottom: 100%;
	}
	&::before {
		z-index: 20;
		position: absolute;
		width: 260px;
		height: 200px;
		content: url('./img_descrip_arrow.svg');
	}
}
.text-underline-purple {
	border-bottom: 4px solid #a474bd;
}
.border-lt {
	border-radius: 0 40px 40px 40px;
}
.border-rt {
	border-radius: 40px 0 40px 40px;
}

.border-rb {
	border-radius: 40px 40px 0 40px;
}

.border-lb {
	border-radius: 40px 40px 40px 0;
}
.bcg {
	&-purple {
		&-1 {
			background: linear-gradient(298.86deg, #bdbfef 0%, #eac4fc 100%);
		}
		&-2 {
			background: #ac62c7;
		}
		&-3 {
			background: #7767b8;
		}
		&-4 {
			background-image: linear-gradient(180deg, #eac4fc 0%, #bdbfef 100%);
			background-origin: border-box;
			box-shadow: inset 100vh 100vw #f4f4f4;
			border: 12px solid transparent;
		}
		&-5 {
			background: linear-gradient(298.86deg, #f1ddfa 0%, #dbdbf4 100%);
			border-radius: 0;
		}
	}
	&-yellow {
		&-1 {
			background: linear-gradient(298.86deg, #fff2af 0%, #ffdb91 100%);
		}
		&-2 {
			background: #ffc786;
		}
		&-3 {
			background: #ffe973;
		}
		&-4 {
			background-image: linear-gradient(180deg, #ffd98e 0%, #fff2af 100%);
			background-origin: border-box;
			box-shadow: inset 100vh 100vw #f4f4f4;
			border: 12px solid transparent;
		}
		&-5 {
			background: linear-gradient(298.86deg, #fff2af 0%, #ffd98e 100%);
			border-radius: 0;
			position: relative;
		}
	}
	&-green {
		&-1 {
			background: linear-gradient(298.86deg, #b2ed9d 0%, #d5fab8 100%);
		}
		&-2 {
			background: #98d374;
		}
		&-3 {
			background: #b0d494;
		}
		&-4 {
			background-image: linear-gradient(180deg, #b6f0a0 0%, #cff8b4 100%);
			background-origin: border-box;
			box-shadow: inset 100vh 100vw #f4f4f4;
			border: 12px solid transparent;
		}
		&-5 {
			background: linear-gradient(298.86deg, #e3f8d6 0%, #d7f3cc 100%);
			border-radius: 0;
			position: relative;
		}
	}
	&-blue {
		&-1 {
			background: linear-gradient(298.86deg, #9dbcf8 0%, #deeffe 100%);
		}
		&-2 {
			background: #90bfef;
		}
		&-3 {
			background: #8d8be8;
		}
		&-4 {
			background: linear-gradient(180deg, #b9cff9 0%, #dcedfe 100%);
			background-origin: border-box;
			box-shadow: inset 100vh 100vw #f4f4f4;
			border: 12px solid transparent;
		}
		&-5 {
			background: linear-gradient(298.86deg, #e7f3ff 0%, #c3d8ff 100%);

			border-radius: 0;
			position: relative;
		}
	}
}
.dote-white {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	&:first-of-type {
		top: 10px;
		left: 10px;
	}
	&:nth-of-type(2) {
		top: 10px;
		right: 10px;
	}
	&:nth-of-type(3) {
		bottom: 10px;
		left: 10px;
	}
	&:nth-of-type(4) {
		bottom: 10px;
		right: 10px;
	}
}
.clip {
	&-blue {
		&-top-right {
			padding-top: 50px;
			&::before {
				content: url('./clip_blue.svg');
				position: absolute;
				top: calc(-24px - #{$padding});
				right: 16px;
				z-index: 2;
			}
		}
		&-top-left {
			padding-top: 50px;
			&::before {
				content: url('./clip_blue.svg');
				position: absolute;
				top: calc(-24px - #{$padding});
				left: 16px;
				z-index: 2;
			}
		}
	}
	&-yellow {
		&-top-right {
			padding-top: 50px;
			&::before {
				content: url('./clip_yellow.svg');
				position: absolute;
				top: calc(-24px - #{$padding});
				right: 16px;
				z-index: 2;
			}
		}
		&-top-left {
			padding-top: 50px;
			&::before {
				content: url('./clip_yellow.svg');
				position: absolute;
				top: calc(-24px - #{$padding});
				left: 16px;
				z-index: 2;
			}
		}
	}
	&-pink {
		&-top-right {
			padding-top: 50px;
			&::before {
				content: url('./clip_pink.svg');
				position: absolute;
				top: calc(-16px - #{$padding});
				right: 16px;
				z-index: 2;
			}
		}
		&-top-left {
			padding-top: 50px;
			&::before {
				content: url('./clip_pink.svg');
				position: absolute;
				top: calc(-16px - #{$padding});
				left: 16px;
				z-index: 2;
				transform: rotateY(180deg);
			}
		}
	}
	&-orange {
		&-top-right {
			padding-top: 50px;
			&::before {
				content: url('./clip_orange.svg');
				position: absolute;
				top: calc(-17px - #{$padding});
				right: 16px;
				z-index: 2;
				transform: rotateY(180deg);
			}
		}
		&-top-left {
			padding-top: 50px;
			&::before {
				content: url('./clip_orange.svg');
				position: absolute;
				top: calc(-17px - #{$padding});
				left: 16px;
				z-index: 2;
			}
		}
	}
	&-green {
		&-top-right {
			padding-top: 50px;
			&::before {
				content: url('./clip_green.svg');
				position: absolute;
				top: calc(-17px - #{$padding});
				height: 100px;
				right: 15px;
				z-index: 2;
			}
		}
		&-top-left {
			padding-top: 50px;
			&::before {
				content: url('./clip_green.svg');
				position: absolute;
				top: calc(-17px - #{$padding});
				height: 100px;
				left: 15px;
				z-index: 2;
			}
			&-img {
				padding-top: 0px;
				&::before {
					content: url('./clip_green.svg');
					position: absolute;
					top: calc(-17px - #{$padding});
					height: 100px;
					left: 15px;
					z-index: 2;
				}
			}
		}
	}
}
.stick-double-up {
	&::before,
	&::after {
		content: url('./double_stick.svg');
		position: absolute;
		bottom: calc(100% - 40px);
		z-index: 2;
	}
	&::before {
		right: 30px;
	}
	&::after {
		left: 30px;
	}
}
.stick-double {
	&::before,
	&::after {
		content: url('./double_stick.svg');
		position: absolute;
		top: calc(100% - 40px);
		z-index: 2;
	}
	&::before {
		right: 30px;
	}
	&::after {
		left: 30px;
	}
}
.text-purple {
	color: #ad48e0 !important;
}
.color_orange {
	cursor: pointer;
	color: #ff900f !important;
	border-bottom: 2px solid #ff900f;
}
.border-green {
	border-bottom: 4px solid #95e777;
}
.list-number {
	list-style: decimal;
}
.link_green {
	color: #4f8168;
	border-bottom: 1px solid #4f8168;
	cursor: pointer;
}
.green {
	color: #4f8168;
}
.textbox-arrow-bl {
	&::after {
		content: ' ';
		position: absolute;
		bottom: 0%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		left: -100px;
		z-index: 2;
		box-shadow: none;
	}
}
.textbox-arrow-br {
	&::after {
		content: ' ';
		position: absolute;
		bottom: 0%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateZ(110deg) rotateY(0deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: -100px;
		z-index: 2;
		box-shadow: none;
	}
}
.textbox-arrow-tr {
	&::after {
		content: ' ';
		position: absolute;
		bottom: 100%;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateZ(230deg) rotateY(180deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		left: calc(100% - 50px);
		z-index: 2;
		box-shadow: none;
	}
}
.textbox-arrow-bl-1 {
	&::after {
		content: ' ';
		position: absolute;
		bottom: -0px;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateZ(230deg) rotateY(180deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: calc(100% + 25px);
		z-index: 2;
		box-shadow: none;
	}
}
.textbox-arrow-tr-2 {
	position: absolute;
	top: -100px;
	right: 10px;
	max-width: calc(100vw - 100px);
	&::after {
		content: ' ';
		position: absolute;
		bottom: -0px;
		background: url('./img_descrip_arrow.svg') 50% 50% no-repeat;
		transform: rotateZ(230deg) rotateY(180deg);
		background-size: contain;
		width: 55px; /* ваш желаемый размер */
		height: 120px; /* ваш желаемый размер */
		right: 100%;
		z-index: 2;
		box-shadow: none;
	}
}

@media (max-width: 1000px) {
	.textbox-arrow-tr {
		display: none;
	}
}
