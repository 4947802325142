$padding: 40px;
$grid-columns: 12;
$grid-gutter-width: 30px;
$row: 140px;

.color_span_orange {
	color: #eba112;
}

.h-100 {
	height: 100%;
}
.mg-1h-b {
	margin-bottom: calc($row / 2);
}
.mg-1h-t {
	margin-top: calc($row / 2);
}
@for $i from 0 through 5 {
	.mg-t-p-#{$i} {
		margin-top: calc(#{$row} * #{$i});
	}
	.mg-b-p-#{$i} {
		margin-bottom: calc(#{$row} * #{$i});
	}

	.pd-v-#{$i} {
		padding-top: calc(#{$row} * #{$i}) !important;
		padding-bottom: calc(#{$row} * #{$i}) !important;
	}
	.pd-t-p-#{$i} {
		padding-top: calc(#{$row} * #{$i});
	}
	.pd-b-p-#{$i} {
		padding-bottom: calc(#{$row} * #{$i});
	}
}
@media (min-width: 576px) {
	@for $i from 1 through 12 {
		.w-sm-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-sm-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-sm-#{$i} {
			margin: calc(#{$row} * #{$i});
		}

		.mg-sm-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-sm-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-sm-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-sm-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-sm-b-m-#{$i}h {
			margin-bottom: calc(#{$row}/ (-2) * #{$i});
		}
		.mg-sm-b-p-#{$i}h {
			margin-bottom: calc(#{$row}/ 2 * #{$i});
		}
		.mg-sm-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-sm-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-sm-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-sm-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-sm-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-sm-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-sm-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i});
		}
		.pd-sm-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i});
		}
		.pd-sm-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-sm-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}

@media (min-width: 768px) {
	@for $i from 1 through 12 {
		.w-md-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-md-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-md-#{$i} {
			margin: calc(#{$row} * #{$i});
		}
		.mg-md-b-m-#{$i}h {
			margin-bottom: calc(#{$row}/ (-2) * #{$i});
		}
		.mg-md-b-p-#{$i}h {
			margin-bottom: calc(#{$row}/ (2) * #{$i});
		}
		.mg-md-t-m-#{$i}h {
			margin-top: calc(#{$row}/ (-2) * #{$i});
		}
		.mg-md-t-p-#{$i}h {
			margin-top: calc(#{$row}/ (2) * #{$i});
		}
		.mg-md-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-md-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-md-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-md-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-md-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-md-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-md-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-md-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-md-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-md-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-md-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i});
		}
		.pd-md-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i});
		}
		.pd-md-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-md-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}
@media (min-width: 992px) {
	@for $i from 1 through 12 {
		.w-lg-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-lg-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-lg-#{$i} {
			margin: calc(#{$row} * #{$i});
		}
		.mg-lg-b-p-#{$i}h {
			margin-bottom: calc(#{$row}/ (2) * #{$i});
		}
		.mg-lg-b-m-#{$i}h {
			margin-bottom: calc(#{$row}/ (-2) * #{$i});
		}
		.mg-lg-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-lg-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-lg-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-lg-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-lg-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-lg-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-lg-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-lg-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-lg-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-lg-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-lg-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i}) !important;
		}
		.pd-lg-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i}) !important;
		}
		.pd-lg-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-lg-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}
@media (min-width: 1200px) {
	@for $i from 1 through 12 {
		.w-xl-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-xl-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-xl-p-#{$i} {
			margin: calc(#{$row} * #{$i});
		}

		.mg-xl-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-xl-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-xl-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-xl-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-xl-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-xl-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-xl-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xl-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xl-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xl-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-xl-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i}) !important;
		}
		.pd-xl-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i}) !important;
		}
		.pd-xl-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-xl-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}
@media (min-width: 1400px) {
	@for $i from 1 through 12 {
		.w-xxl-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-xxl-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-xxl-p-#{$i} {
			margin: calc(#{$row} * #{$i});
		}

		.mg-xxl-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-xxl-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-xxl-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-xxl-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-xxl-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-xxl-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-xxl-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xxl-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xxl-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xxl-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-xxl-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i}) !important;
		}
		.pd-xxl-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i}) !important;
		}
		.pd-xxl-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-xxl-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}
@media (max-width: 576px) {
	.h-xs-100 {
		height: 100%;
	}
	@for $i from 1 through 12 {
		.w-xs-#{$i} {
			width: calc(#{$row} * #{$i});
		}
		.h-xs-#{$i} {
			height: calc(#{$row} * #{$i});
		}
	}

	@for $i from 0 through 5 {
		.mg-xs-#{$i} {
			margin: calc(#{$row} * #{$i});
		}

		.mg-xs-h-#{$i} {
			margin: 0 calc(#{$row} * #{$i});
		}
		.mg-xs-v-#{$i} {
			margin: calc(#{$row} * #{$i}) 0;
		}

		.mg-xs-t-p-#{$i} {
			margin-top: calc(#{$row} * #{$i});
		}
		.mg-xs-b-p-#{$i} {
			margin-bottom: calc(#{$row} * #{$i});
		}
		.mg-xs-r-p-#{$i} {
			margin-right: calc(#{$row} * #{$i});
		}
		.mg-xs-l-p-#{$i} {
			margin-left: calc(#{$row} * #{$i});
		}

		.mg-xs-b-m-#{$i} {
			margin-bottom: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xs-r-m-#{$i} {
			margin-right: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xs-l-m-#{$i} {
			margin-left: calc(#{$row} * #{$i} * (-1));
		}
		.mg-xs-t-m-#{$i} {
			margin-top: calc(#{$row} * #{$i} * (-1));
		}

		.pd-xs-t-p-#{$i} {
			padding-top: calc(#{$row} * #{$i});
		}
		.pd-xs-b-p-#{$i} {
			padding-bottom: calc(#{$row} * #{$i});
		}
		.pd-xs-r-p-#{$i} {
			padding-right: calc(#{$row} * #{$i});
		}
		.pd-xs-l-p-#{$i} {
			padding-left: calc(#{$row} * #{$i});
		}
	}
}
