.leftButton {
	position: absolute;
	bottom: 30px;
	left: 80px;
	cursor: pointer;
	z-index: 200;
}
.btn_back {
	position: absolute;
	left: 20px;
	top: 100px;
	z-index: 100;
	font-family: Hachi Maru Pop;
	font-size: 20px;
	font-weight: 400;
	line-height: 52px;
	letter-spacing: 0em;
	text-align: center;
	cursor: pointer;
	color: #000000;
	
}
.btn_back::after {
	cursor: pointer;
	content: url('./Arrow_back.png');
	position: absolute;
	top: 50%;
	left: -0%;
}
.btn_back:hover {
	cursor: pointer;
}
.btn_back::after:hover {
	cursor: pointer;
}
@media (max-width: 960px) {
	.btn_back {
		cursor: pointer;
		position: absolute;
		left: 20px;
		top: 0px;
	}
}

.leftButton_lection {
	position: absolute;
	top: 70px;
	left: 80px;
	cursor: pointer;
	z-index: 1;
}

@media (max-width: 1450px) {
	.leftButton {
		position: absolute;
		bottom: 30px;
		left: 50px;
		cursor: pointer;
		width: 74px;
		height: 74px;
	}
}
@media (max-width: 1130px) {
	.leftButton {
		position: absolute;
		bottom: 30px;
		left: 40px;
		cursor: pointer;
		width: 64px;
		height: 64px;
	}
}

@media (max-width: 960px) {
	.leftButton_lection {
		position: absolute;
		top: 10px;
		left: 20px;
		cursor: pointer;
	}
}
@media (max-width: 450px) {
	.leftButton_lection {
		position: absolute;
		cursor: pointer;
		width: 35px;
		height: 35px;
	}
}
