@font-face {
	font-family: 'Comic Sans MS';
	src: url(https://fonts.gstatic.com/l/font?kit=WWXUljiDYgyJJ8igRvwjKZhbdw_vzQ&skey=a4ba60ff9fc73cf8&v=v19)
		format('woff2');
}


:root {
	--primary-color: #b85232;
	--secundary-color: #746031;
	--title-color: #fcc100;
	--link-color: #efb800;
	--gray: #808080;
	--primary-font: 'Montserrat';
	--secundary-font: 'Comic Sans MS', 'Comic Sans';
	--third-font: 'Pangolin';
	--title-color__second-module: #885b9f;
	--highlight-text__second-module: #ad48e0;
	--points_color: #c292e0;
}
