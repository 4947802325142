.footerContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
	position: relative;
	z-index: 20;
}

.footerTitle {
	color: #404040;
	text-align: center;
	font-family: 'Montserrat';
	font-size: clamp(1.25rem, 0.2557rem + 2.6515vw, 3.4375rem);
	font-style: normal;
	font-weight: 600;
	line-height: 95.9%; /* 34.524px */
}

.text_white {
	color: #fff !important;
}

.partnersContainer {
	display: flex;
	flex-direction: row;
	grid-gap: 45px;
	margin: clamp(2.5rem, 0.7955rem + 4.5455vw, 6.25rem) 0;
}
#partnersContainerDesktop {
	display: flex;
}

.footerContact {
	display: flex;
	grid-gap: 21px;
	align-items: center;
	margin-bottom: 30px;
	position: relative;
	z-index: 100;
}

.footerContact a {
	color: white;
	text-decoration: none;
	font-weight: 500;
}
.partner_img {
	width: 100%;
	height: auto;
}
.img {
	width: clamp(1.5625rem, 0.2841rem + 3.4091vw, 4.375rem);
}


@media (max-width: 1050px) {
	#partnersContainerDesktop {
		display: none;
	}
}

