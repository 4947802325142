@font-face {
	font-family: 'Comic Sans';
	src: local('Comic Sans'),
		url('./fonts/ComicSansMS/ComicSansMS.ttf') format('truetype'),
		url('./fonts/ComicSansMS/ComicSansMS.woff') format('woff'),
		url('./fonts/ComicSansMS/ComicSansMS.woff2') format('woff2');
}

@font-face {
	font-family: 'Hachi Maru Pop';
	src: local('Hachi Maru Pop'),
		url('./fonts/Hachi_Maru_Pop/HachiMaruPop-Regular.ttf') format('truetype'),
		url('./fonts/Hachi_Maru_Pop/HachiMaruPop-Regular.woff') format('woff'),
		url('./fonts/Hachi_Maru_Pop/HachiMaruPop-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'),
		url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
		url('./fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
		url('./fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'Pangolin';
	src: local('Pangolin'),
		url('./fonts/Pangolin/Pangolin-Regular.ttf') format('truetype'),
		url('./fonts/Pangolin/Pangolin-Regular.woff') format('woff'),
		url('./fonts/Pangolin/Pangolin-Regular.woff2') format('woff2');
}

* {
	padding: 0px;
	margin: 0px;
	border: none;
}
body {
	-webkit-text-size-adjust: auto;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
}

ul,
ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img,
svg {
	height: auto;
}

address {
	font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button,
input[type='submit'] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
	outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}
