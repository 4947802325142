.fact {
	background: #ffffff;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	svg {
		width: 80vw;
	}
}
.fact-text {
	font-family: 'Montserrat';
	font-size: 21px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: center;
}
.fact-title {
	width: 100%;
	font-family: 'Hachi Maru Pop';
	font-size: clamp(1.5rem, 1rem + 2.5vw, 4rem);
	font-weight: 400;
	line-height: 194px;
	letter-spacing: -0.21em;
	text-align: center;
	color: #00000012;
	&::after {
		content: '!';
	}
	&::before {
		content: '#';
	}
}
.fact-container {
	width: 80%;
	height: 50%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
