@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.lection {
	max-width: 100vw;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: #f4f4f4;
}
.list {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	list-style: disc;
	&-item {
		margin-bottom: 20px;
		list-style: disc;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}
}
.link-purple {
	text-decoration: underline !important;
	color: #ad48e0 !important;
}
.link-green {
	text-decoration: underline !important;
	color: #819a38 !important;
	&-dark {
		text-decoration: underline !important;
		color: #58902d !important;
	}
}
.text {
	&-italic {
		font-family: 'Montserrat';
		font-size: 16px;
		font-style: italic;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0em;
		text-align: left;
	}

	&-link {
		cursor: pointer;
		color: #000000;
		list-style: disc;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0em;
		text-align: left;
		text-decoration: underline !important;
	}
	&-title {
		font-family: 'Hachi Maru Pop';
		font-size: 36px;
		font-weight: 400;
		line-height: 52px;
		letter-spacing: 0em;
		text-align: center;
		margin: 0;
	}
	&-subtitle {
		//styleName: Text/подзаголовок;
		font-family: 'Hachi Maru Pop';
		font-size: 20px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: center;
		margin: 0;
	}
	&-medium {
		//styleName: Text/style;
		list-style: disc;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}
	&-bold {
		//styleName: Text/style;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}
	&-semi {
		//styleName: Text/style;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}
	&-big-semi {
		font-family: 'Montserrat';
		font-size: 24px;
		font-weight: 600;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}
	&-img {
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
	}
	&-center {
		text-align: center;
		margin: 0;
	}
	&-orange {
		color: #eba112 !important;
	}
	&-pink {
		color: #ad48e0 !important;
	}
}
.underline-pink {
	border-bottom: 1px solid #ad48e0;
}
.close {
	display: none !important;
	position: relative;
}
.open {
	display: block;
	position: absolute;
}
.overlay-br {
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	left: 100%;
	top: 100%;
	width: max-content;
	height: 100%;
	max-width: 280px;
}
.overlay-bl {
	height: 100%;
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	right: 100%;
	top: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
}
.overlay-br {
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	left: 100%;
	top: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
	height: 100%;
}
.overlay-bl {
	box-sizing: border-box;
	content: var(--after-content);
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	right: 100%;
	top: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
	height: max-content;
}
.overlay-tr {
	box-sizing: border-box;
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	left: 100%;
	bottom: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
	height: max-content;
}
.overlay-tl {
	box-sizing: border-box;
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	right: 100%;
	bottom: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
	height: max-content;
}
.overlay-tl {
	box-sizing: border-box;
	padding: 20px 40px;
	border: 6px solid #f3f3ff;
	border-radius: 40px 0px 40px 40px;
	position: absolute;
	z-index: 20;
	right: 100%;
	top: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	max-width: 280px;
	height: max-content;
}

.overlay {
	position: relative;
	&-bcg {
		&-1 {
			&::after {
				background: linear-gradient(
					97.03deg,
					#fde3ac 8.8%,
					#fde3ac 8.8%,
					#fffceb 49.4%,
					#ffe9bc 95.39%
				);
			}
		}
		&-2 {
			&::after {
				background: linear-gradient(
					97.03deg,
					#c1c1f4 8.8%,
					#e5c7f7 49.4%,
					#d2d2f9 95.39%
				);
			}
		}
		&-3 {
			&::after {
				background: linear-gradient(
					97.03deg,
					#aee39b 8.8%,
					#d6fcb9 49.4%,
					#aee09c 95.39%
				);
			}
		}
		&-4 {
			&::after {
				background: linear-gradient(
					97.03deg,
					#c2d7ff 8.8%,
					#ddeefe 49.4%,
					#cadcff 95.39%
				);
			}
		}
	}
}
