.container {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh; /* Changed from min-height to max-height */
    z-index: 220;
    background: rgba(255, 255, 255, 0.37);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.content {
    position: relative;
    width: 500px;
    height: auto;
    min-width: 310px;
    padding: 20px;
    border-radius: 15px;
    background: #8aae3f;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow:
        0 4px 6px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.08);
    overflow-y: auto; /* Added this line */
    max-height: 100vh; /* Added this line */
}
.content::-webkit-scrollbar {
	width: 0px; /* Ширина ползунка */
   
}


.btn_close{
    position: absolute;
    top:15px;
    right: 15px;
}

.title {
    text-align: center;
    margin: 0 0 15px 0;
    padding: 15px;
    box-sizing: border-box;
    flex-shrink: 0;
    color: #303030;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 106.9%;
    /* 24.587px */
    border: 0;
    margin-bottom: 10px;
}

.form {
    width: 100%;
}

.field {
    margin-bottom: 15px;
}

.field_title {
    margin: 0 0 0 15px;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.field_inp {
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    font-family: 'Montserrat';
    border: 0;
    border-radius: 30px;
    width: 100%;
    padding: 5px  15px;
    height: 40px;
    background: #f9fcf3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

}

.select_box {
    padding: 0;
    background: #f9fcf3; 
    border-radius: 30px;
    width: 100%;
    padding: 5px  15px;
    height: 40px;
    position: relative;
}

.field_inp_big {
    background: #f9fcf3;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    border-radius: 30px;
    width: 100%;
    padding: 5px  15px;
    height: 40px; 
    outline: none;
    resize: none;
}
.field_inp_big::-webkit-scrollbar{
    width: 0px;
}
.btn_submit {
    padding: 15px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 38.5px;
    background: #f0c042;
    color: #303030;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 106.9%;
    /* 24.587px */
    border: 0;
    margin-bottom: 10px;

}

.dropdown {
    border: 3px solid #526727;
    width: 100%;
    background: #f9fcf3;
    margin-bottom: 10px;
    position: relative;
    top:5px;
    border-radius: 0 0 15px 15px;
}

.dropdownList {
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f9fcf3;
    border-radius:   15px;
    
    button {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        font-family: "Montserrat";
        display: flex;
        align-items: center;
        border-bottom: 1px solid #303030;
    }
    :last-child{
        border-bottom: 0;
    }
    
    
}


@media (max-width: 800px) {
    .content {
        width: 90%;
    }
    .field_inp,.field_inp_big {
        font-size: 16px;
    }
    .dropdownList {
        height: 100%;
        margin: 0;
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        background: #f9fcf3;
        border-radius:  0 0 15px 15px;
        
        button {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 550;
            font-family: "Montserrat";
            display: flex;
            align-items: center;
        }
    
    }
}
