.avatarBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow {
  cursor: pointer;
  height: 120px;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.1s;
  width: 80px;
}
.arrow-top, .arrow-bottom {
  background-color: #666;
  height: 2px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 23px;
}
.arrow-top:after, .arrow-bottom:after {
  background-color: #fff;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}
.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}
#partnersContainerMobile {
  display: none;
}
.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}
.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}
.arrow:active {
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}

.partnersContainer {
	display: flex;
	flex-direction: row;
	grid-gap: 45px;
	margin: clamp(2.5rem, 0.7955rem + 4.5455vw, 6.25rem) 0;
  position: relative;
  margin-left: 15px;
}
.arrow_left {
  margin-top: 52px;
  margin-right: -25px;
  width: 50px;
  right: 240px;
  top: 80px;
  transform: rotate(180deg);
}
.arrow_left:active {
  transform: translateX(-50%) translateY(-50%) scale(0.9);
  transform: rotate(180deg);
}


.avatar_name {
  color: #b3a87d;
  background: local;
  text-align: center;
  font-family: 'Montserrat';
  font-size: clamp(8px, 25vw, 13px);
  font-style: normal;
  font-weight: 500;
  line-height: 106.9%;
  margin: 0;
  /* padding: 5px 0 0 0; */
  padding: 0;
  border: 0;
  word-wrap: break-word;
  width: 100%;
}
.partnerContainer {
  transition: all 1s ease-in-out;
}

.arrow_right {
  margin-top: 100px;
 
  width: 50px;
  left: 235px;
  top: 80px;
}

@media (max-width: 1717px) {
  .arrow_left {
    right: 230px;
  }

  .arrow_right {
    left: 230px;
  }
}

@media (max-width: 1500px) {
  .arrow_left {
    right: 180px;
  }

  .arrow_right {
    left: 170px;
  }
}

@media (max-width: 1350px) {
  .arrow_left {
    width: 35px;
    /* right: 200px; */
  }

  .arrow_right {
    width: 35px;
    /* left: 200px; */
  }
}

@media (max-width: 1224px) {
  .arrow_left {
    width: 35px;
    top: 35px;
    right: 150px;
  }

  .arrow_right {
    width: 35px;
    top: 35px;
    left: 150px;
  }
}
@media (max-width:1050px) {
  #partnersContainerMobile {
    display: flex;
  }
}

@media (max-width: 750px) {
  .arrow_left {
   margin-top: 7%;
  }

  .arrow_right {
    margin-top: 15%;
    margin-left: -12px;
  }
}
@media (max-width: 650px) {
	.partner_img {
		width: 130%;
		height: auto;
	}
	.partnersContainer {
		height: 138px;
    grid-gap: 30px;
	}
  .arrow_left {
    margin-top: 5%;
    margin-right: -14px;
   }
 
  .arrow_right {
    margin-top: 14%;
    margin-left: 0px;
  }
	.footerTitle {
	}
}
@media (max-width: 500px) {
	.partner_img {
		width: 130%;
		height: auto;
	}
  .arrow-top, .arrow-bottom {
    background-color: #666;
    height: 2px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 14px;
  }
	.partnersContainer {
		height: 100px;
    grid-gap: 30px;
    margin: clamp(1.5rem, 0.7955rem + 1.5455vw, 6.25rem) 0;
    margin-left: 15px;
	}
  .arrow_left {
    margin-top: -1%;
    margin-right: -14px;
   }
 
  .arrow_right {
    margin-top: 11%;
    margin-left: 0px;
  }
	.footerTitle {
	}
}
@media (max-width: 400px) {
	
  .arrow_left {
    margin-top: -5%;
    margin-right: -1px;
   
   }
  .partnersContainer {
    grid-gap: 15px;
  }
  .arrow_right {
    margin-left: 8px;
  }

}
@media (max-width: 345px) {
	
  .arrow_left {
    margin-top: -9%;
   
   }

}
@media (max-width: 300px) {
	
  .arrow_left {
    margin-top: -9%;
    margin-right: 3px;
   
   }
   .partnersContainer {
    height: 80px;
    grid-gap: 6px;
  }
 
  
  .arrow_right {
    margin-left: 9px;
    margin-top: 15%;
  }

}