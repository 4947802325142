.newsItem {
	box-sizing: border-box;
	position: relative;
	box-sizing: border-box;
	background: url('./bcg.svg') 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 25% 75%;
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	align-items: center;
	min-height: 180px;
	padding-top: 50px;
	padding-bottom: 100px;
	margin-top: -100px;
}
.newsItem::after {
	content: url('../NewsItem/flower_left.png');
	position: absolute;
	left: -25px;
	bottom: 40px;
	z-index: 5;
}
.newsItem::before {
	content: url('../NewsItem/flower_right.png');
	position: absolute;
	right: -40px;
	bottom: 40px;
	z-index: 5;
}

.newsItem_full {
	box-sizing: border-box;
	position: relative;
	box-sizing: border-box;
	background: #f9f5ec;
	background-size: cover;
	width: 100%;
	height: 100%;
	max-width: 1080px;
	min-height: 250px;
	display: grid;
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	align-items: center;
	min-height: 180px;
	margin: 0 auto;
}

.navBar {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 20px 0;
}

.navBar_full {
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 100px;
}
.news_name {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	color: #1c1f04;
	font-family: 'Montserrat';
	font-size: clamp(1.125rem, 1.034rem + 0.3883vw, 1.5rem);
	font-style: normal;
	font-weight: 900;
	line-height: 160%; /* 38.4px */
	text-align: left;
	margin-bottom: 1%;
}

.date {
	min-height: 110px;
	height: auto;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: url('./date_blue.png') no-repeat 50% 50%;
	background-size: contain;
}
.date_text {
	padding: 60px;
	width: max-content;
	color: #fff;
	text-align: right;
	font-family: Pangolin;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-align: center;
	background: url('./date_orange.png') no-repeat 50% 50%;
	background-size: contain;
}
.date_text_blue {
	padding: 60px;
	width: max-content;
	color: #fff;
	text-align: right;
	font-family: Pangolin;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	text-align: center;
	background: url('./date_blue.png') no-repeat 50% 50%;
	background-size: contain;
}

.animals_img {
	width: 90%;
	height: auto;
}

.position_btn_container {
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: center;
	min-height: 70px;
	padding: 2%;
}

.bird_animal {
	position: relative;
	left: -20%;
}

.animal_saiga {
	width: 95%;
	position: relative;
	left: -5px;
}

.container {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;
	padding: 20px 0;
}

.title {
	color: #1c1f04;
	font-family: 'Montserrat';
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.news_full {
	color: #000;
	text-align: justify;
	font-family: 'Montserrat';
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.btn {
	background: local;
	border: 0;
	text-decoration: none;
	width: 100%;
	height: auto;
	background: url('./btn_bcg.png') no-repeat 50% 50%;
	background-size: contain;
}

.btn_full {
	background: local;
	border: 0;
	text-decoration: none;
	width: 100%;
	height: auto;
	background: url('./btn_bcg.png') no-repeat 50% 50%;
	background-size: contain;
}
.btn_arrow_revert {
	padding-left: 10px;
	transform: rotate(180deg);
}
.btn_container {
	margin: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 103%;
	justify-content: center;
	min-height: 70px;
	padding: 2%;
}
.btn_bcg {
	width: 100%;
	height: auto;
	flex-shrink: 0;
}
.btn_text {
	color: #f9f5ec;
	font-family: 'Montserrat';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 160%; /* 25.6px */
	/* margin-right: 5%; */
}

.btn_arrow {
	width: 5%;
	height: auto;
}

.news_short {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 5%;
	box-sizing: border-box;
	align-items: center;
	height: 100%;
	width: 95%;
}
.news_container {
	padding: 0 25px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}
.news {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;

	img {
		box-sizing: border-box;
		margin: auto;
		height: auto;
		max-width: 100%;
	}

	h1 {
		box-sizing: border-box;
		min-width: 100%;
		margin: 0;
		color: #1c1f04;
		font-family: 'Montserrat';
		font-size: clamp(1.125rem, 1.034rem + 0.3883vw, 1.5rem);
		font-style: normal;
		font-weight: 900;
		line-height: 160%; /* 38.4px */
		text-align: left;
		margin-bottom: 1%;
	}
	p {
		box-sizing: border-box;
		margin: 0;
		color: #1c1f04;
		font-family: 'Montserrat';
		font-size: clamp(0.875rem, 0.7992rem + 0.3236vw, 1.1875rem);
		font-style: normal;
		font-weight: 570;
		line-height: 120%; /* 38.4px */
		text-align: left;
		margin-bottom: 10px;
		max-width: 100%;
		min-width: 100%;
	}
	h2 {
		box-sizing: border-box;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		min-width: 100%;
		text-align: left;
		margin: 0 0 5px 0;
		font-size: clamp(0.8125rem, 0.767rem + 0.1942vw, 1rem);
	}
	figcaption {
		box-sizing: border-box;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		overflow-x: auto;
		width: 100%;
		margin: 0 0 5px 0;
		font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
	}
	h3 {
		box-sizing: border-box;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		min-width: 100%;
		margin: 0 0 5px 0;
		font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
	}
	h4 {
		box-sizing: border-box;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		min-width: 100%;
		margin: 0 0 5px 0;
		font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
	}
	h5 {
		box-sizing: border-box;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		min-width: 100%;
		margin: 0 0 5px 0;
		font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
	}
	h6 {
		box-sizing: border-box;
		text-align: left;
		font-style: normal;
		font-weight: 500;
		line-height: 120%; /* 38.4px */
		font-family: 'Montserrat';
		min-width: 100%;
		margin: 0 0 5px 0;
		font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
	}
	figcaption {
		margin-top: 10px;
		text-align: center;
		box-sizing: border-box;
	}
	hr {
		box-sizing: border-box;
		width: 100%;
		height: 1px;
		background: #000;
	}
	figure {
		overflow-x: auto;
		overflow-y: hidden;
		box-sizing: border-box;
		height: auto;
		min-height: max-content;
		flex-direction: column;
		margin: 20px auto;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		::-webkit-scrollbar {
			width: 15px;
		}

		::-webkit-scrollbar-track {
			background: black;
			color: black;
		}

		::-webkit-scrollbar-thumb {
			background: white;
			border-radius: 15px;
		}
		scrollbar-width: thin;
		scrollbar-color: white black;
	}

	table {
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		border-collapse: collapse;
	}
	tbody {
		width: 100%;
	}
	th,
	td {
		box-sizing: border-box;
		border: 1px solid black;
		text-align: left;
		width: 80%;
		padding: 5px;
	}

	ol {
		padding-left: 0;
		width: 85%;
		text-align: left;
		list-style-type: disc;
	}
	ul {
		padding-left: 0;
		width: 90%;
		text-align: left;
		list-style-type: disc;
	}
	li {
		box-sizing: border-box;
		list-style: inherit;
	}
	a {
		box-sizing: border-box;
		color: #000;
		cursor: pointer;
		border-bottom: 1px solid;
	}
	blockquote {
		box-sizing: border-box;
		min-width: 100%;
		margin: 0;
		display: flex;
		flex-direction: row;
		font-family: 'Montserrat';
		font-size: clamp(0.875rem, 0.7992rem + 0.3236vw, 1.1875rem);
		font-style: normal;
		font-weight: 570;
		line-height: 120%; /* 38.4px */
		width: 100%;
		text-align: left;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		margin-bottom: 10px;
		p {
			box-sizing: border-box;
			color: #8b8b8b;
			width: min-content;
			min-width: 0px;
			max-width: max-content;
		}
		p:first-of-type {
			margin-right: 10px;
			font-style: italic;
			padding-right: 4px;
		}
		p:first-of-type::before,
		p:first-of-type::after {
			content: '"';
		}
	}
}

.date_mobile {
	display: none;
}
.btn_more {
	display: none;
}
.content {
	box-sizing: border-box;
	padding: 50px 40px 10px 20px;
	width: 100%;
	height: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (max-width: 1000px) {
	figure {
		width: 100% !important;
	}
	.newsItem {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		justify-content: space-between;
		margin: auto;
		width: 100%;
		padding: 20px 0;
		min-height: 250px;
		margin-top: 25px;
		border-radius: 15px;
		background: #f9f5ec;
	}
	.newsItem::after,
	.newsItem::before {
		content: '';
		display: none;
	}
	.newsItem_full {
		background: #f9f5ec;
	}
	.news {
		width: 100%;
		padding: 0;
	}
	.navBar_full,
	.navBar {
		display: none;
	}

	.date_mobile {
		display: block;
		margin: 0;
		width: 100%;
		color: #1c1f04;
		font-family: 'Montserrat';
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 38.4px */
		text-align: left;
	}
	.btn_more {
		display: block;
		width: min-content;
		height: min-content;
		background: local;
		border-radius: 15px;
		min-height: 0px;
		/* padding: 2%; */
		margin-left: 25px;
		padding: 5px 10px;
		border: 1px solid #1c1f04;
	}
	.btn_text {
		display: block;
		margin: 0;
		color: #1c1f04;
		font-family: 'Montserrat';
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 38.4px */
		text-align: left;
	}
	.content {
		box-sizing: border-box;
		padding: 20px;
		width: 100%;
		height: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.container {
		justify-content: space-between;
		flex-direction: column;
	}
}

@media (max-width: 500px) {
	.btn_more {
		padding: 3px 10px;
	}
	.content {
		padding: 10px;
		margin: auto;
		max-width: 95vw;
	}
}
